/* eslint-disable sonarjs/no-duplicate-string */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const self_host = process.env.REACT_APP_SELF_HOST;
export const endpoint = process.env.REACT_APP_API_ENDPOINT;
export const filePath =
  process.env.REACT_APP_FILE_PATH_ENDPOINT || 'https://dev.sota.id';

export const PAGINATION_SIZE = 6;

export const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const VIDEO_WIDTH_COL_SPAN = 17;
export const TOTAL_COL_BY_ANT = 24;

export const MICRO_TIMEOUT = 50;
export const MACRO_TIMEOUT = 300;

export const HARDCODED_COLOR = [
  '#FF7875',
  '#FFA940',
  '#FADB14',
  '#95DE64',
  '#5CDBD3',
  '#69B1FF',
  '#B37FEB',
  '#FF85C0',
  '#000000',
  '#FFFFFF',
  '#F5F5F5',
];

export const KEYKODES_TO_CHAR: any = {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  16: 'Shift',
  17: 'Ctrl',
  18: 'Alt',
  19: 'Pause/Break',
  20: 'Caps Lock',
  27: 'Esc',
  32: 'Space',
  33: 'Page Up',
  34: 'Page Down',
  35: 'End',
  36: 'Home',
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  45: 'Insert',
  46: 'Delete',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  65: 'A',
  66: 'B',
  67: 'C',
  68: 'D',
  69: 'E',
  70: 'F',
  71: 'G',
  72: 'H',
  73: 'I',
  74: 'J',
  75: 'K',
  76: 'L',
  77: 'M',
  78: 'N',
  79: 'O',
  80: 'P',
  81: 'Q',
  82: 'R',
  83: 'S',
  84: 'T',
  85: 'U',
  86: 'V',
  87: 'W',
  88: 'X',
  89: 'Y',
  90: 'Z',
  91: 'Windows',
  93: 'Right Click',
  96: 'Numpad 0',
  97: 'Numpad 1',
  98: 'Numpad 2',
  99: 'Numpad 3',
  100: 'Numpad 4',
  101: 'Numpad 5',
  102: 'Numpad 6',
  103: 'Numpad 7',
  104: 'Numpad 8',
  105: 'Numpad 9',
  106: 'Numpad *',
  107: 'Numpad +',
  109: 'Numpad -',
  110: 'Numpad .',
  111: 'Numpad /',
  112: 'F1',
  113: 'F2',
  114: 'F3',
  115: 'F4',
  116: 'F5',
  117: 'F6',
  118: 'F7',
  119: 'F8',
  120: 'F9',
  121: 'F10',
  122: 'F11',
  123: 'F12',
  144: 'Num Lock',
  145: 'Scroll Lock',
  182: 'My Computer',
  183: 'My Calculator',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '\\',
  221: ']',
  // eslint-disable-next-line quotes
  222: "'",
};

export const CHAR_TO_KEYCODES = {
  Backspace: 8,
  Tab: 9,
  Enter: 13,
  Shift: 16,
  Ctrl: 17,
  Alt: 18,
  'Pause/Break': 19,
  'Caps Lock': 20,
  Esc: 27,
  Space: 32,
  'Page Up': 33,
  'Page Down': 34,
  End: 35,
  Home: 36,
  Left: 37,
  Up: 38,
  Right: 39,
  Down: 40,
  Insert: 45,
  Delete: 46,
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  Windows: 91,
  'Right Click': 93,
  'Numpad 0': 96,
  'Numpad 1': 97,
  'Numpad 2': 98,
  'Numpad 3': 99,
  'Numpad 4': 100,
  'Numpad 5': 101,
  'Numpad 6': 102,
  'Numpad 7': 103,
  'Numpad 8': 104,
  'Numpad 9': 105,
  'Numpad *': 106,
  'Numpad +': 107,
  'Numpad -': 109,
  'Numpad .': 110,
  'Numpad /': 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  'Num Lock': 144,
  'Scroll Lock': 145,
  'My Computer': 182,
  'My Calculator': 183,
  ';': 186,
  '=': 187,
  ',': 188,
  '-': 189,
  '.': 190,
  '/': 191,
  '`': 192,
  '[': 219,
  '\\': 220,
  ']': 221,
  // eslint-disable-next-line quotes
  "'": 222,
};

export const FIELD_IMAGES: any = {
  1: [
    {
      value: 'no_stripes.png',
      label: 'Без полосок',
    },
    {
      value: '20_positive.png',
      label: '20 позитивных',
    },
    {
      value: '20_negative.png',
      label: '20 негативных',
    },
    {
      value: '18_positive.png',
      label: '18 позитивных',
    },
    {
      value: '18_negative.png',
      label: '18 негативных',
    },
    {
      value: '22_positive.png',
      label: '22 позитивных',
    },
    {
      value: '22_negative.png',
      label: '22 негативных',
    },
  ],
  8: [
    {
      value: 'futsal_no_stripes.png',
      label: 'Без полосок',
    },
  ],
  9: [
    {
      value: 'no_stripes.png',
      label: 'Без полосок',
    },
    {
      value: '20_positive.png',
      label: '20 позитивных',
    },
    {
      value: '20_negative.png',
      label: '20 негативных',
    },
    {
      value: '18_positive.png',
      label: '18 позитивных',
    },
    {
      value: '18_negative.png',
      label: '18 негативных',
    },
    {
      value: '22_positive.png',
      label: '22 позитивных',
    },
    {
      value: '22_negative.png',
      label: '22 негативных',
    },
  ],
};
export const SPORTS: any = {
  1: 'football',
  8: 'futsal',
  9: 'football_30',
};

export const SPORTS_IMAGE_ASPECT_RATIO: any = {
  footbal: { id: 1, aspectRatio: '227/147' },
  futsal: { id: 8, aspectRatio: '456/227' },
  football_30: { id: 9, aspectRatio: '227/147' },
};
